body.gopro {
	background: #f1f1f1;
}

.unnanu-pro-wrapper {

	.go-pro-header {
		padding-top: 150px;
		background-image: url("../../images/gopro-header-bg.svg");
		background-color: #369eff;
		background-repeat: no-repeat;
		background-position: 0 0;
		background-size: 100% auto;
		height: 422px;
		width: 100%;
		position: relative;
		z-index: 0;
		color: #fff;
		text-align: center;

		.go-pro-title {
			font-family: 'Frank Ruhl Libre', serif;
			margin: 0 0 11px;
			font-size: 36px;
			font-weight: 500;
			line-height: 1.11;
		}

		.go-pro-subtitle {
			height: 48px;
			margin: 0 0 40px;
			font-size: 16px;
			line-height: 1.5;
			letter-spacing: 0.4px;
		}
	}

	button.btn-go-pro {
		width: 195px;
		height: 48px;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
		background-color: #ffffff;
		border-radius: 0;
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 0.4px;
		color: #317eff;
		transition: .2s;

		svg {
			margin-top: -6px;
			margin-right: 8px;
		}

		&:hover {
			//background-color: #255dba;
		}

		&:focus {
			color: #317eff;
		}
	}

	.unannu-pro-content {
		margin-top: 40px;
		text-align: center;

		.text-content {
			font-size: 17px;
			font-weight: 400;
			color: #868686;
		}

		.go-pro-subheader {
			margin: 0;
			font-size: 24px;
			color: #222222;
		}

		.steps-wrapper {
			overflow: auto;
			width: 790px;
			margin: 30px auto;
			position: relative;

			&:before {
				content: "";
				display: block;
				width: 200px;
				border-top: 2px solid #e2e2e2;
				position: absolute;
				top: 105px;
				left: 38%;
				z-index: 1;
			}

			&:after {
				z-index: 3;
				content: "";
				display: block;
				width: 30px;
				height: 30px;
				position: absolute;
				top: 92px;
				left: 48%;
				background-color: rgba(0, 0, 0, 0);
				background-image: url("../../images/gopro-step-link.png?1");
				background-repeat: no-repeat;
			}

			.step {
				position: relative;
				z-index: 2;
				text-align: left;
				float: left;
				width: 358px;
				height: 230px;
				padding: 30px;
				border-radius: 5px;
				box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
				background-color: #ffffff;

				.step-title {
					margin: 23px 0 9px;
					font-size: 24px;
					color: #222222;
				}

				.step-content {
					margin: 0;
					font-size: 15px;
					line-height: 1.6;
					color: #777777;
				}

				&.step-2 {
					float: right;
				}
			}

		}

		.step-wrap-content {
			width: 790px;
			padding: 0 66px 30px;
			margin: 0 auto;
			font-size: 14px;
			line-height: 1.57;
			text-align: center;
			color: #777777;
			border-bottom: solid 1px rgba(0, 0, 0, 0.12);
		}

		.go-pro-price-wrapper {
			margin: 25px auto 75px;
			width: 790px;

			h2 {
				font-size: 32px;
				color: #222222;
			}

			button.btn-go-pro {
				width: 191px;
				box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
				background-color: #369eff;
				color: #ffffff;

				svg {
					path {
						fill: #ffffff;
					}
				}
			}

			.text-content {
				margin: 25px 0 30px;
				font-size: 14px;
				line-height: 1.57;
				text-align: center;
				color: #777777;
			}

			.verification-date-details {
				margin-top: 15px;
				font-size: 12px;
				color: #979797;
			}
		}
	}

}

.ngdialog {
	&.ngdialog-theme-unnanu-v2 {
		&.ssn-popup {
			.ngdialog-content {
				width: 733px;
				height: 455px;
			}

			.ssn-left {
				width: 219px;
				height: 455px;
				border-radius: 1px;
				padding: 184px 76px 183px;
				background-color: #317eff;
			}

			.ssn-right {
				position: relative;

				.ngdialog-header {
					p {
						margin: 10px 0 20px;
						font-size: 16px;
						line-height: 1.5;
						letter-spacing: 0.4px;
						color: #777777;
					}
				}

				.ngdialog-body {
					.input-wrapper {
						.dob-wrap {
							width: 260px;
							margin-right: 10px;
						}

						.postal-code-wrap {
							width: 184px;
						}
					}
				}

				.ngdialog-footer {
					bottom: 0;
					width: 100%;
					position: absolute;
				}
			}
		}

		&.gopro-popup {
			.ngdialog-content {
				width: 733px;
				min-height: 650px;
			}

			.propopup-left {
				text-align: center;
				width: 194px;
				min-height: 650px;
				border-radius: 1px;
				padding: 284px 63px 0;
				background-color: #317eff;
			}

			.propopup-right {
				position: relative;

				.ngdialog-header {
					p {
						margin: 10px 0 20px;
						font-size: 16px;
						line-height: 1.5;
						letter-spacing: 0.4px;
						color: #777777;
					}
				}

				.ngdialog-body {
					padding-bottom: 114px;

					.choose-plan {
						margin-bottom: 20px;

						h2 {
							color: #222222;
							font-size: 15px;
							font-weight: 500;
							margin: 0 0 15px;
							letter-spacing: 0.3px;
						}

						.plan-options {
							padding-bottom: 20px;
							border-bottom: solid 0.6px rgba(0, 0, 0, 0.12);

							.plan-option {
								width: 234px;
								cursor: pointer;
								position: relative;

								.plan {
									width: 100%;
									height: 60px;
									transition: .2s;
									line-height: 27px;
									border-radius: 5px;
									background-color: #ffffff;
									border: solid 0.6px rgba(0, 0, 0, 0.12);
									box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);

									&.active {
										border: solid 1.5px #317eff;

										svg {
											path {
												fill: #317eff;
											}
										}

										.plan-price {
											color: #3f82f1;
										}

										.verified-icon {
											display: block;
										}
									}

									&:hover {
										border: solid 1.5px #317eff;
									}

									svg {
										top: 50%;
										left: 20px;
										position: absolute;
										transform: translateY(-50%);

										path {
											fill: #c9c9c9;
										}
									}

									.plan-price {
										top: 15px;
										left: 56px;
										height: 29px;
										font-size: 24px;
										color: #222222;
										line-height: 31px;
										position: absolute;

										.plan-term {
											font-size: 16px;
										}
									}

									.discount {
										top: 10px;
										right: 10px;
										width: 41px;
										height: 41px;
										font-size: 12px;
										font-weight: 500;
										color: #777777;
										line-height: 16px;
										position: absolute;
										border-radius: 5px;
										padding: 5px 6px 6px 7px;
										background-color: #ededed;
									}
								}
							}
						}
					}

					.error-msg {
						width: 100%;
						font-size: 16px;
						color: #ff4b4b;
						padding: 5px 10px;
						border-radius: 5px;
						background-color: #ffe1e1;
						border: 1px solid #ff4b4b;
					}

					.card-details-wrapper {

						.name-wrapper {
							width: 284px;
							margin-right: 15px;
						}

						.card-types-wrapper {
							margin-top: 6px;
						}

						.card-number-wrapper {
							width: 230px;
							margin-right: 10px;
						}

						.card-expire-wrapper {
							width: 155px;
							margin-right: 10px;

							.dash {
								font-size: 16px;
								font-weight: 500;
								color: #222222;
								letter-spacing: 0.4px;
								padding: 14px 5px 5px;
							}
						}

						.card-cvv-wrapper {
							width: 74px;
						}
					}

					.privacy-agree {
						font-size: 14px;
						color: #222222;
						line-height: 1.57;
						margin-bottom: 20px;
						letter-spacing: 0.4px;

						a {
							color: #2a7aff;
						}
					}

					.description {
						font-size: 14px;
						line-height: 1.57;
						letter-spacing: 0.4px;
						color: #777777;
					}
				}

				.ngdialog-footer {
					bottom: 0;
					width: 100%;
					position: absolute;

					.next-button {
						width: 205px;
						padding: 0;
						transform: width .5s;

						.signinsignupPreloader {
							top: 9px;
						}

						&.loading {
							width: 230px;
							padding-left: 20px;
							text-align: left;
						}
					}
				}
			}
		}
	}
}
