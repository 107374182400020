@keyframes ngbmodal-flyin {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-40px);
		transform: translateY(-40px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes ngbmodal-flyout {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateY(-40px);
		transform: translateY(-40px);
	}
}

.modal-content {
	-webkit-animation: ngbmodal-flyin .5s;
	animation: ngbmodal-flyin .5s;
	background: #ffffff;
	border-radius: 0;
	color: #444;
	font-family: 'Lato', sans-serif;
	font-size: 1.1em;
	line-height: 1.5em;
	margin: 80px auto 40px;
	//max-width: 100%;
	position: relative;
	width: 500px;
}

.modal-body {
	margin-bottom: .5em;
}

.modal-footer {
	//*zoom: 1;

	&:after {
		content: '';
		display: table;
		clear: both;
	}

	.ok-button {
		border: 0;
		cursor: pointer;
		margin: 0 0 0 2px;
		text-transform: uppercase;

		&:focus {
			-webkit-animation: ngdialog-pulse 1.1s infinite;
			animation: ngdialog-pulse 1.1s infinite;
			outline: none;
		}

		@media (max-width: 568px) {
			&:focus {
				-webkit-animation: none;
				animation: none;
			}
		}
	}
}

.alert-pop-up-style-v2 {
	.modal-content {
		padding: 0;
	}

	.modal-header {
		border: 0;
		margin: 0;
		padding: 28px 24px 14px;

		h1 {
			margin: 0;
			color: #222222;
			font-size: 24px;
			letter-spacing: 0.4px;
		}

		h4 {
			font-size: 22px;
			margin: 0;
		}
	}

	.modal-body {
		padding: 0 24px;
		font-size: 16px;
		letter-spacing: 0.1px;
		line-height: 22px;

		p {
			color: #777777;
		}
	}

	.modal-footer {
		padding: 15px 19px;
		border: 0;

		.button-wrapper {
			.btn {
				font-size: 19px;
				font-weight: 500;
				color: #52a7db;
				text-transform: uppercase;
				border: 0;
				background: none;

				&:hover {
					background: none;
				}
			}

			.no-button {
				color: #989898;
			}
		}
	}
}

.request_more_info {
	.modal-content {
		width: 440px;
		height: auto;
	}

	.modal-header {
		border: 0;
		padding: 28px 24px 14px;
	}

	.modal-body {
		padding: 0 24px;
		font-size: 16px;
		letter-spacing: 0.1px;
		line-height: 22px;

		.cancel-text {
			color: #777777;
		}

		ul {
			.missing-profile-information {
				color: #ff6969;
				padding: 15px;
				list-style: none;

				li {
					margin-bottom: 5px;

					&:last-child {
						margin-bottom: 5px;
					}
				}
			}
		}
	}

	.modal-footer {
		padding: 30px 19px 16px;
		border: 0;

		.button-wrapper {
			button {
				background: none;

				&:hover {
					background: none;
				}

				.btn {
					font-size: 19px;
					font-weight: 500;
					color: #52a7db;
					text-transform: uppercase;
					border: 0;
				}

				.no-button {
					color: #989898;
				}
			}
		}
	}
}

.info-new {
	.modal-content {
		padding: 0;
	}

	.modal-header {
		border-bottom: 1px solid #f1f1f1;
		margin: 0;
		padding: 14px 19px;

		h4 {
			margin: 0;
		}
	}

	.modal-body {
		margin-bottom: 0;
		padding: 14px 19px;

		p {
			margin-bottom: 0;
		}

		textarea {
			resize: none;
		}
	}

	.modal-footer {
		border-top: 1px solid #f1f1f1;
		padding: 14px 19px;

		.button-wrapper {
			margin-top: 0;
		}
	}
}

.alert-popup-common {
	.modal-content {
		padding: 40px 40px 10px;
		width: 485px;
		background-color: #fff;
	}

	.modal-header {
		padding-bottom: 20px;
		border-bottom: 1px solid #f1f1f1;
	}
}

.upload-resume-dialog {
	.modal-content {
		display: -ms-flexbox;
		display: flex;
		padding: 30px 34px;
		width: 570px;
	}

	.modal-header {
		padding: 0px;
		border: none;

		h3 {
			margin: 0 0 10px;
			font-size: 24px;
			letter-spacing: 0.4px;
			color: #222222;
			line-height: 1.5;
		}
	}

	.modal-body {
		padding: 0px;

		p {
			margin: 0 0 20px;
			font-size: 16px;
			line-height: 1.57;
			letter-spacing: 0.4px;
			color: #222222;

			&.size {
				font-size: 14px;
				line-height: 1.57;
				color: #777777;
			}
		}

		.uploading-wrapper {
			width: 100%;
			height: 60px;
			border-radius: 5px;
			border: solid 0.7px rgba(0, 0, 0, 0.2);
			background-color: #f1f1f1;
			margin-bottom: 15px;
			position: relative;
			padding: 17px 18px;
			font-size: 17px;
			font-weight: 500;

			.upload-button {
				svg {
					margin-top: -3px;
					margin-right: 12px;
				}

				font-size: 16px;
				font-weight: 500;
				line-height: 1.5;
				letter-spacing: 0.4px;
				color: #606060;
			}

			.custom-file {
				cursor: pointer;
				height: 58px;
				left: 0;
				opacity: 0;
				width: 100%;
				position: absolute;
				top: 0;
				z-index: 1;

				#file {
					cursor: pointer;
				}
			}

			p {
				color: rgba(0, 0, 0, 0.8);
				cursor: default;
				font-size: 17px;
				font-weight: 500;
				position: relative;
			}

			&.uploading {
				p:before {
					background-image: url(../../../assets/images/refreshing.png);
					background-position: center;
					background-size: cover;
					content: "";
					display: block;
					height: 20px;
					left: 155px;
					position: absolute;
					top: 3px;
					width: 20px;
					-webkit-animation: rotateSpinner 1.2s linear infinite;
					animation: 1.2s linear 0s normal none infinite running rotateSpinner;
				}
			}

			&.uploaded {
				.dlt {
					background-image: url(../../../assets/images/dlt.png);
					background-position: center;
					background-size: cover;
					cursor: pointer;
					display: block;
					height: 21px;
					width: 20px;
					position: absolute;
					top: 17px;
					right: 18px;
				}
			}
		}
	}

	.modal-footer {
		display: block;
		text-align: right;
		height: 84px;
		margin: 0 -34px -15px;
		padding: 18px 30px;
		background-color: rgba(0, 0, 0, 0.03);

		.save-changes-button {
			width: 135px;
		}

		.upload-new-file-button {
			height: 35px;
			box-shadow: none;
			background: none;
			border: 0;
			border-radius: 0;
			font-size: 13px;
			font-weight: 700;
			letter-spacing: 0.4px;
			color: #266adc;
			position: relative;
			cursor: pointer;
			padding: 0;

			button {
				border: 0;
				box-shadow: none;
				cursor: pointer;
				padding: 0;
				height: 35px;
				color: #266adc;
			}

			.custom-file-input {
				top: 0;
				left: 0;
				height: 35px;
				position: absolute;
				cursor: pointer;
			}

			&.large {
				height: 48px;
				font-size: 16px;

				button {
					height: 48px;
				}

				.custom-file-input {
					cursor: pointer;
					height: 48px;
				}
			}

			&:hover {
				button {
					background: none;
					color: #266adc;
				}
			}
		}
	}
}

.dual-column-dialog.edit-profile-dialog,
.dual-column-dialog.edit-resume-dialog {
	.modal-content {
		width: 873px;
		padding: 0;
		margin: 80px -159px;

		.modal-header {
			border: none;

			.close {
				padding: 2rem 1rem 1rem 1rem;
				font-size: 2.5rem;
				font-weight: 400;
			}
		}

		.modal-body {
			padding: 0px;
		}

		.modal-footer {
			background-color: #f7f7f7;
			border: none;
			padding-right: 1.5rem !important;
			margin-left: -30px;
			margin-right: -30px;
		}
	}
}

.dual-column-dialog.edit-profile-dialog {
	.modal-content {
		.modal-header {
			padding: 0px;
		}
	}
}

.modal-theme-unnanu-v2 {
	&.modal-opening {
		.close {
			display: none !important;
		}
	}

	&.modal-closing {
		.close {
			display: none !important;
		}

		.modal-content {
			-webkit-animation: ngdialog-flyout .5s;
			animation: ngdialog-flyout .5s;
		}
	}

	&.no-close-button {
		.close {
			display: none !important;
		}
	}

	.close {
		right: 30px;
		top: 16px;
		width: 40px;
		height: 40px;
		border: 0;
		position: fixed;
		cursor: pointer;
		border-radius: 40px;
		background-size: cover;
		background: url('../../../assets/images/close-button-popup.svg') no-repeat;

		&:before,
		&:after {
			display: none;
		}
	}

	.modal-content {
		-webkit-animation: ngbmodal-flyin .5s;
		animation: ngbmodal-flyin .5s;
		background: #ffffff;
		border-radius: 1px;
		color: #444;
		font-size: 1.1em;
		line-height: 1.5em;
		margin: 80px auto 40px;
		max-width: 100%;
		position: relative;
		width: 500px;

		.modal-v2 {
			.modal-header {
				padding: 30px 30px 0;

				h1 {
					margin: 0;
					color: #222222;
					font-size: 24px;
					letter-spacing: 0.4px;
				}
			}

			.modal-body {
				color: #222222;
				font-size: 16px;
				line-height: 1.5;
				letter-spacing: 0.4px;
				padding: 10px 30px 30px;
			}

			.modal-footer {
				min-height: 84px;
				padding: 18px 30px;
				background-color: rgba(0, 0, 0, 0.03);
			}
		}
	}

	&.popup-xs {
		.modal-content {
			width: 450px;
		}
	}
}

.video-recorder-popup {
	.modal-content {
		width: 374px;

		.modal-header {
			padding: 26px 33px 28px;

			h1 {
				margin: 0;
				font-size: 24px;
				letter-spacing: 0.6px;
				color: #222222;
			}
		}

		.modal-body {
			padding: 0px !important;
			height: 374px;

			.video-camera-panel {
				position: relative;
				width: 374px;
				height: 374px;
				overflow: hidden;
				background: #000;
			}

			.camera-feed-wrapper {
				width: 374px;
				height: 374px;
				overflow: hidden;

				.photo-preview-wrapper {
					position: relative;
					width: 374px;
					height: 374px;
					overflow: hidden;
					background: #000;

					#imagePreview {
						position: absolute;
						left: 50%;
						height: 374px;
						transform: translateX(-50%);
					}
				}
			}
		}

		.modal-footer {
			padding: 18px 20px;

			.recordButton {
				width: 1000%;
				height: 48px;
			}
		}
	}
}

.unnanuPopupStyle01 {
	.modal-content {
		.modal-header {
			margin: -28px -26px 0 -26px;
			padding: 16px 26px;
			margin-bottom: 16px;
			background-color: #f8f8f8;
			border-radius: 5px 5px 0 0;

			h4 {
				margin: 0;
				font-size: 16px;
				color: #6c6c6c;
			}

			.close {
				font-size: 22px;
				top: 14px;
				right: 14px;
			}
		}

		.modal-body {
			margin-bottom: 0;

			.live-search-gmail {
				height: 45px;
				box-shadow: none;
				font-size: 16px;
				border-color: #eeeeee;
				padding-left: 13px;
				padding-right: 13px;
			}

			.contact-list {
				position: relative;
				border: 1px solid #eeeeee;
				border-radius: 3px;
				height: 217px;
				overflow: auto;
				background-color: #f8f8f8;
				padding-top: 5px;

				.contact-row {
					position: relative;
					display: block;

					label {
						display: block;
					}

					.contact-email {
						line-height: 28px;
						padding: 2px 12px;
						font-size: 13px;
						font-weight: 500;
						cursor: pointer;
					}

					.contact-check {
						float: right;
						margin-right: 12px;

						input {
							margin-top: 8px;
						}
					}
				}
			}

			.contact-select-all {
				margin-top: 10px;

				a {
					color: #123f9b;
					font-size: 13px;
					font-weight: 500;
				}
			}

			button.referCandidatesButton {
				color: #2152bd;
				width: 153px;
				height: 40px;
			}

			button.referCandidatesButton:hover {
				background-color: #2152bd;
				color: #ffffff;
			}
		}
	}
}

.onboarding {
	.modal-content {
		padding: 0;
		width: 660px;

		.modal-header {
			padding: 30px 30px 20px;
		}

		.modal-body {
			padding: 0;

			.scroll-content {
				background-color: #f1f1f1;
				max-height: 520px;
				overflow: auto;
				width: 100%;

				.item-wrapper {
					background-color: #fff;
					float: left;
					margin-bottom: 10px;
					padding: 16px 34px 20px;
					width: 100%;

					&:last-child {
						margin-bottom: 0;
					}

					h3 {
						font-size: 16px;
						font-weight: 700;
						line-height: 1.5;
						letter-spacing: 0.4px;
						color: #222222;
						margin: 0;
						height: 24px;
						max-width: 350px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					p {
						float: left;
						margin-top: 10px;
						margin-bottom: 15px;
						word-wrap: break-word;
						width: 100%;
						font-size: 16px;
						line-height: 1.5;
						letter-spacing: 0.4px;
						color: #777777;
					}

					.status {
						color: #ffffff;
						font-size: 13px;
						font-weight: 500;
						float: left;
						height: 26px;
						line-height: 26px;
						margin-left: 9px;
						padding: 0 8px;
						letter-spacing: 0.3px;
						width: -moz-fit-content;
						width: fit-content;
					}

					.status.waiting {
						background-color: #f4bd3a;
					}

					.status.sent {
						background-color: #4bc082;
					}

					.status.received {
						background-color: #36a3f7;
					}

					.status.decline {
						background-color: #ff2424;
					}

					.msg-wrapper {
						float: left;
						width: 100%;
						max-height: 110px;
						margin-bottom: 15px;
						overflow-y: auto;
						overflow-x: hidden;
					}

					.button-wrapper {
						float: left;
						width: 100%;

						.btn-view {
							background-color: #ffffff;
							border: solid 2px #36a3f7;
							color: #36a3f7;
							font-size: 15.3px;
							margin-right: 15px;
						}

						.attach-send {
							margin-left: 10px;
						}

						.btn-send {
							background-color: #34a2f7;
							color: #ffffff;
							font-size: 15.3px;

							&:disabled {
								background-color: rgba(54, 163, 247, 0.2) !important;
								color: rgba(54, 163, 247, 0.26);
							}

							input {
								display: block;
								height: 29px;
								left: 0;
								opacity: 0;
								padding: 19px;
								position: absolute;
								top: -2px;
								width: 100%;
							}
						}

						.info-wrapper {
							p.sent {
								color: #36a3f7;
								cursor: default;
								font-size: 15.3px;
								margin-bottom: 0;
								margin-top: 8px;
								position: relative;

								span {
									background-image: url(../../images/success-1.png);
									background-size: cover;
									background-position: center;
									display: inline-block;
									height: 20px;
									margin-right: 5px;
									position: relative;
									top: 5px;
									width: 20px;
								}
							}

							p.uploading {
								span {
									background-image: url(../../images/spin.gif);
									height: 40px;
									left: -40px;
									position: absolute;
									top: -8px;
									width: 40px;
								}
							}

							p.attachments {
								span {
									background-image: url(../../images/arrowMoreSettings-blue.png);
									background-size: cover;
									height: 12px;
									margin-left: 5px;
									transform: rotate(90deg);
									position: relative;
									top: 1px;
									width: 8px;
								}
							}

							p.attachments.up {
								span {
									transform: rotate(270deg);
								}
							}
						}
					}

					.due-date {
						float: left;
						margin-top: 10px;
						font-size: 14px;
						letter-spacing: 0.4px;
						color: #868686;
					}

					.attachment-wrapper {
						border-top: 1px solid rgba(#000, 0.05);
						float: left;
						margin-top: 20px;
						padding-top: 0;
						width: 100%;

						.attachment-item {
							border-bottom: 1px solid rgba(#000, 0.05);
							float: left;
							width: 100%;

							&:last-child {
								border: 0 none;
							}

							p {
								color: #36a3f7;
								font-size: 13px;
								line-height: 1.22;
								margin-top: 4px;
								text-decoration: underline;
								width: auto;

								a {
									color: #36a3f7;
									font-size: 13px;
									line-height: 1.22;
									text-decoration: underline;
									margin: 0;
								}

								span {
									background-size: cover;
									display: inline-block;
									height: 23px;
									margin-right: 11px;
									position: relative;
									top: 7px;
									width: 17px;
								}

								.text {
									background-image: url("../../images/text-file.png");
									margin-left: 2px;
								}

								.pdf {
									background-image: url("../../images/pdf-file.png");
									width: 21px;
								}

								.doc {
									background-image: url("../../images/doc-file.png");
									width: 21px;
								}
							}

							.status {
								margin-top: 9px;
							}
						}
					}
				}
			}
		}
	}
}

.cancel-subscription-pop {
	.modal-content {
		width: 492px;
		height: auto;
		padding: 10px 20px;
	}

	.modal-header {
		h3 {
			font-size: 19px;
			color: #303030;
			font-weight: 500;
		}
	}

	.modal-body {
		p {
			font-size: 17px;
			color: #000000;
			font-weight: 400;
		}

		a {
			color: #23527c;
		}
	}

	.modal-footer {
		.button-wrapper {
			float: right;
			margin-top: 0;

			button {
				background-color: rgba(0, 0, 0, 0);
				font-size: 16px;
				color: #0680de;
			}

			button.disabled {
				color: #939393;
			}
		}
	}
}

.popup-xs {
	.modal-content {
		width: 450px;
	}
}

.notification-modal {
	.modal-content {
		border: none;
		background-color: transparent;
	}
}
