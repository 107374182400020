button {
	position: relative;
	cursor: pointer;

	svg {
		vertical-align: middle;
	}

	&:disabled {
		background-color: #dddde0;
		color: #ffffff;
		opacity: 1;
		-moz-opacity: 1;
		-khtml-opacity: 1;
		border: none !important;
		box-shadow: none !important;
		pointer-events: none;
	}
}

.blue-button {
	svg {
		margin-top: -4px;
		margin-right: 8px;
	}

	text-align: center;
	width: 145px;
	height: 48px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	background-color: #369eff;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 0.4px;
	color: #ffffff;
	border:0;
	border-radius: 0;

	&.large {
		width: 145px;
		height: 48px;
	}

	&.loading {
		border: 0;
		box-shadow: none;

		svg {
			display: none;
		}
	}

	&:hover {
		color: #ffffff;
		background-color: #2567d5;
	}
}

.transparent-button {
	font-size: 16px;
	background: none;
	font-weight: 500;
	color: #777777;
	text-align: center;
	letter-spacing: 0.5px;
	border-radius: 1px;
	border: none;

	&.large {
		width: 135px;
		height: 48px;
	}

	&:hover {
		color: #000000;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;
		background: none;
		color: #777777;
	}
}

.yellow-button {
	border-radius: 1px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	border: solid 0.6px rgba(0, 0, 0, 0.2);
	background-color: #ffba00;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.63;
	letter-spacing: 0.4px;
	color: #000000;
	font-weight: 500;

	&:hover {
		color: #000000;
		background-color: #FFA300;
	}

	&.large {
		width: 184px;
		height: 48px;
	}
}



// need to rewrite bellow buttons by reusing in mind
.login-button {
	height: 50px;
	color: #000000;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.63;
	border-radius: 1px;
	letter-spacing: 0.4px;
	background-color: #ffba00;
	border: solid 0.6px rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

	&:hover {
		background-color: #FFA300;
	}
}

.continue-button {
	width: 135px;
	height: 48px;
	font-weight: 700;
	border-radius: 0;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	border: solid 0.6px rgba(0, 0, 0, 0.2);
	background-color: #ffba00;
	color: #000000;

	&:hover {
		color: #000000;
		background-color: #FFA300;
	}

	&:disabled {
		color: rgba(34, 34, 34, 0.16);
		background-color: rgba(0, 0, 0, 0.08);
	}
}

.skip-button {
	width: 116px;
	height: 48px;
	font-weight: 700;
	border-radius: 0;
	background: none !important;
	color: #266adc;

	&:hover {
		color: #266adc;
	}
}

.cancel-button {
	width: 135px;
	height: 48px;
	font-size: 16px;
	background: none;
	font-weight: 500;
	color: #777777;
	text-align: center;
	letter-spacing: 0.5px;
	border-radius: 1px;
	border: none;

	&.white {
		box-shadow: 0 1px 3px 0 rgba(169, 169, 169, 0.2);
		border: solid 0.6px rgba(0, 0, 0, 0.12);
		background-color: #ffffff;
	}

	&:hover {
		color: #000000;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;
		background: none;
		color: #777777;
	}
}

.appCard-addButton {
	width: 184px;
	height: 48px;
	border-radius: 1px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	border: solid 0.6px rgba(0, 0, 0, 0.2);
	background-color: #ffba00;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.63;
	letter-spacing: 0.4px;
	color: #000000;

	&:hover {
		background-color: #FFA300;
	}
}


.upgrade-button {
	svg {
		margin-top: -4px;
		margin-right: 8px;
	}

	text-align: center;
	width: 145px;
	height: 48px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	background-color: #369eff;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 0.4px;
	color: #ffffff;
	border:0;
	border-radius: 0;

	&.loading {
		border: 0;
		box-shadow: none;

		svg {
			display: none;
		}
	}

	&:hover {
		color: #ffffff;
		background-color: #2567d5;
	}

	&.pending {
		svg {
			opacity: 0.16;

			path {
				fill: #000;
			}
		}

		color: rgba(0, 0, 0, 0.16);
		background-color: rgba(0, 0, 0, 0.08);
		box-shadow: none;

		&:hover {
			color: rgba(0, 0, 0, 0.16);
			background-color: rgba(0, 0, 0, 0.08);
		}
	}

	&.verified {
		svg {
			path {
				fill: #0fbb85;
			}
		}

		color: #0fbb85;
		background-color: rgba(15, 187, 133, 0.2);
		box-shadow: none;

		&:hover {
			color: #0fbb85;
			background-color: rgba(15, 187, 133, 0.2);
		}
	}
}


.save-button {
	width: 145px;
	height: 48px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	background-color: #369eff;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 0.4px;
	text-align: center;
	color: #ffffff;
}

.next-button {
	width: 135px;
	height: 48px;
	font-size: 16px;
	font-weight: 700;
	color: #ffffff;
	letter-spacing: 0.4px;
	background-color: #317eff;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	border-radius: 0;
	position: relative;

	&:hover {
		color: #ffffff;
	}
}

.submit-button {
	width: 134px;
	height: 48px;
	border-radius: 1px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	border: solid 0.6px rgba(0, 0, 0, 0.2);
	background-color: #ffba00;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.63;
	letter-spacing: 0.4px;
	text-align: center;
	color: #000000;

	&:hover {
		color: #000000;
		background-color: #FFA300;
	}
}



button.message-archive-button,
button.message-unarchive-button {
	height: 40px;
	border-radius: 1px;
	box-shadow: 0 1px 3px 0 rgba(169, 169, 169, 0.2);
	border: solid 0.6px rgba(0, 0, 0, 0.12);
	background-color: #ffffff;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.4px;
	color: #222222;
}

button.message-unarchive-button {}

//User Profile
button.item-video-sound-control {
	position: absolute;
	left: 0;
	bottom: 0;
	background: none;
	padding: 0;
	margin: 0;
	width: 64px;
	height: 64px;
	border: none;

	.audio-control-icon-img {
		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		top: 50%;
		transition: opacity 0.3s ease-in-out;
	}

	.mute-icon {
		opacity: 0;
	}

	.sound-icon {
		opacity: 1;
	}

	&.mute {
		.mute-icon {
			opacity: 1;
		}

		.sound-icon {
			opacity: 0;
		}
	}
}

button.edit-profile-button {
	svg {
		margin-top: -4px;
		margin-right: 8px;
	}

	text-align: center;
	width: 145px;
	height: 48px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
	border: solid 1.5px #317eff;
	background-color: #ffffff;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 0.4px;
	color: #317eff;
	border-radius: 0;

	&.loading {
		border: 0;
		box-shadow: none;

		svg {
			display: none;
		}
	}

	&:hover {
		color: #369eff;
		border-color: #369eff;

		svg {
			path {
				fill: #369eff;
			}
		}
	}
}

button.add-new-card-button {
	background: none !important;
	font-size: 16px;
	/* Madhu changed font-weight from 500 to 600 */
	font-weight: 600;
	letter-spacing: 0.4px;
	color: #266adc;

	&:hover {
		color: #266adc;
	}
}

button.edit-entry-button,
button.updating-entry-button {
	border: 0;
	font-size: 16px;
	color: #90949c !important;
	background: none !important;

	&.btn-sm {
		padding: 1px 5px;
	}
}

button.view-more-button,
button.view-less-button {
	height: 22px;
	line-height: 22px;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.57;
	letter-spacing: 0.4px;
	color: #266adc !important;
	background: none !important;
	padding: 0;
	border: 0;
}

.save-changes-button {
	height: 35px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	background: #317eff;
	border: 0;
	border-radius: 0;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: 0.4px;
	color: #ffffff;

	&.large {
		padding: 0 24px;
		height: 48px;
		font-size: 16px;
	}

	&:hover {
		background: #2567d5;
	}
}

.cancel-changes-button {
	height: 35px;
	box-shadow: none;
	background: none;
	border: 0;
	border-radius: 0;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: 0.4px;
	color: #777777;

	&.large {
		padding: 0 24px;
		height: 48px;
		font-size: 16px;
	}

	&:hover {
		color: #777777;
		background: none;
	}
}

.delete-card-button {
	height: 35px;
	box-shadow: none;
	background: none;
	border: 0;
	border-radius: 0;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: 0.4px;
	color: #ff5252;

	&.large {
		padding: 0 24px;
		height: 48px;
		font-size: 16px;
	}

	&:hover {
		color: #ff5252;
		background: none;
	}
}

.alert-popup-dismiss-button {
	border: 0;
	height: 35px;
	font-size: 13px;
	color: #0680de;
	box-shadow: none;
	background: none;

	&.large {
		height: 48px;
		font-size: 16px;
	}
}

.upload-new-file-button {
	height: 35px;
	box-shadow: none;
	background: none;
	border: 0;
	border-radius: 0;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: 0.4px;
	color: #266adc;
	position: relative;
	cursor: pointer;

	button {
		border: 0;
		box-shadow: none;
		cursor: pointer;
		padding: 0;
		height: 35px;
		color: #266adc;
	}

	.custom-file-input {
		top: 0;
		left: 0;
		height: 35px;
		position: absolute;
		cursor: pointer;
	}

	&.large {
		height: 48px;
		font-size: 16px;

		button {
			height: 48px;
		}

		.custom-file-input {
			cursor: pointer;
			height: 48px;
		}
	}

	&:hover {
		button {
			background: none;
			color: #266adc;
		}
	}
}

//from job portal
button.view-all-btn {
	width: 100%;
	height: 31px;
	border-radius: 15.5px;
	background-color: #ffffff;
	font-size: 13px;
	font-weight: bold;
	line-height: 1.46;
	letter-spacing: 0.4px;
	color: #266adc;

	@media only screen and (min-width:1025px) {
		box-shadow: 0 1px 3px 0 rgba(169, 169, 169, 0.2);
	}
}

.remove-button {
	background: none;
	height: 30px;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.58;
	text-align: right;
	color: #317eff;

	svg {
		margin-top: -1px;
		margin-right: 4px;
	}
}

.signup-button {
	width: 134px;
	height: 35px;
	border-radius: 1px;
	border: solid 0.6px rgba(0, 0, 0, 0.12);
	background-color: #ffba00;
	font-size: 15px;
	font-weight: 500;
	text-align: center;
	color: #000000;

	&.large {
		width: 177px;
		height: 50px;
		font-size: 18px;
		//line-height: 1.44;
	}

	&:hover {
		background-color: #edad00;
	}
}

.zoom-call-button {
	width: 207px;
	height: 48px;
	border-radius: 1px;
	box-shadow: 0 1px 3px 0 rgba(169, 169, 169, 0.2);
	background-color: #317eff;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 0.4px;
	text-align: center;
	color: #ffffff;

	&:hover {
		color: #ffffff;
	}

	img {
		margin-left: 6px;
		margin-top: -2px;
	}
}

.record-video-button {
	width: 334px;
	height: 48px;
	font-size: 16px;
	font-weight: 700;
	color: #ffffff;
	border-radius: 0;
	text-align: center;
	letter-spacing: 0.4px;
	background-color: #17b06a;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

	svg {
		margin-top: -3px;
		margin-right: 6px;
	}

	.stop-icon,
	.stop-text,
	.record-time {
		display: none;
	}

	&.recording {
		background-color: #ff5252;

		.stop-icon,
		.stop-text {
			display: inline-block;
		}

		.start-icon,
		.start-text {
			display: none;
		}

		.record-time {
			display: inline-block;
		}

		&:hover {
			background-color: #F13232;
		}
	}

	&:hover {
		color: #ffffff;
		background-color: #11DC7F;
	}
}

.retake-video-button,
.save-video-button {
	width: 164px;
	height: 48px;
	font-size: 16px;
	border-radius: 0;
	color: #000000;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.4px;
	background: #ffffff;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

	&:hover {
		color: #000000;
		background: #EBEBEB;
	}
}

.save-video-button {
	background-color: #317eff;
	color: #ffffff;

	&:hover {
		color: #ffffff;
		background: #2567d5;
	}
}

.play-card-video {
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 0.4px;
	color: #ffffff;
	background: none;
	border: 0;
	padding: 0;
	width: 33px;
	height: 33px;
	box-shadow: none;
	line-height: 32px;

	&:hover {
		color: #ffffff;
	}

	img {
		//float: left;
		//margin-right: 12px;
	}
}
