//Upload Profile Photo Video
.camera-menu {
	position: absolute;
	z-index: 999;
	width: 95px;
	height: 95px;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 0;

	button.btn {
		padding: 0;
		border: 0;
		width: 95px;
		height: 95px;
		background: none;

		&.edit-camera-button {}
	}

	.dropdown-menu {
		//left: inherit;
		//right: 10px;
		font-size: 14px;
		padding: 5px 0;
		margin-top: 0;
		border: 0;
		left: -33px !important;
		box-shadow: 0px 2px 10px #ccc;

		&:before {
			position: absolute;
			transform: translateX(-50%);
			top: -10px;
			left: 50%;
			display: inline-block;
			border-right: 10px solid transparent;
			border-bottom: 10px solid #fff;
			border-left: 10px solid transparent;
			border-bottom-color: #ffffff;
			content: "";
		}

		.dropdown-item {
			display: block;
			padding: 3px 20px;
			clear: both;
			font-weight: 400;
			line-height: 1.42857143;
			color: #333;
			white-space: nowrap;
		}

		button.dropdown-item {
			cursor: pointer;
		}

		.upload-image {
			position: relative;
			cursor: pointer;

			input#uploadedImage {
				position: absolute;
				width: 100%;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				opacity: 0;
			}
		}
	}
}

// App Card Widget
.appCardPanel {
	border-radius: 1px;
	box-shadow: 0 1px 3px 0 rgba(169, 169, 169, 0.2);
	background-color: #ffffff;

	.panel-heading {
		padding: 30px 30px 0;

		.panel-title {
			margin: 0;
			font-size: 24px;
			letter-spacing: 0.4px;
			color: #222222;
		}

		p {
			margin: 0;
			font-size: 16px;
			line-height: 1.5;
			letter-spacing: 0.4px;
			color: #777777;
		}
	}

	.panel-body {
		padding: 14px 30px 30px;

		.empty-card-text {
			margin: 0;
			font-size: 16px;
			line-height: 1.5;
			letter-spacing: 0.4px;
			color: #777777;
		}
	}

	.panel-footer {
		text-align: right;
		padding: 18px 30px;
		background-color: #f7f7f7;

		.animated-background {
			height: 15px;

			&.content {
				margin-top: 4px;
				width: 200px;
			}
		}

		.custom-file {
			position: absolute;
			right: 30px;
			bottom: 18px;
			width: 184px;
			opacity: 0;
			z-index: 1;
			height: 1px;

			#file {
				cursor: pointer;
				width: 100%;
			}
		}

		.custom-file:hover+button.appCard-addButton {
			border: 1px solid #e5e5e5;
			color: #303030;
			background-color: #f0f0f0;
		}
	}
}

.editEntry,
.editDeleteEntry,
.updatingEntry {
	position: absolute;
	top: 0px;
	right: 15px;
}

.panel-button-wrapper {
	padding-bottom: 20px;
}

// Preloaders
.signinsignupPreloader {
	display: inline-block;
	width: 30px;
	height: 30px;
	position: absolute;
	top: 10px;
	right: 10px;
}

.signinsignupPreloader:after {
	content: '';
	display: inline-block;
	width: 30px;
	height: 30px;
	background-image: url(../../../assets/images/icon-preload-white.png?1);
	-webkit-animation: 1.2s linear 0s normal none infinite running rotateSpinner;
	animation: 1.2s linear 0s normal none infinite running rotateSpinner;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 1;
}

//Preloading animations
i.preloader-icon-v1 {
	-webkit-animation: 1.2s linear 0s normal none infinite running rotateSpinner;
	animation: 1.2s linear 0s normal none infinite running rotateSpinner;
	background-color: transparent;
	background-image: url(../../../assets/images/icon-preload-gray.png);
	background-position: 0 0;
	background-repeat: repeat;
	background-size: cover;
	display: block;
	height: 24px;
	width: 24px;
}

//Uploading Content...
.uploadingStatusWrapper {
	position: absolute;
	right: 25px;
	top: 50px;
	float: left;
	font-size: 16px;
	color: #90949c;

	&:after {
		content: "";
		display: block;
		width: 30px;
		height: 30px;
		background-image: url("../../images/icon-preload.png?1");
		-webkit-animation: rotateSpinner 1.2s linear infinite;
		background-repeat: no-repeat;
		background-position: center;
		position: absolute;
		right: -30px;
		top: 50%;
		margin-top: -15px;
		z-index: 1;
	}
}

//errors
select.error,
input.error {
	border-color: red !important;
}

label.error {
	font-weight: 400 !important;
	color: red !important;
	font-size: 12px !important;
	margin: 0 !important;
	line-height: 1 !important;
}

//Form / Inout Groups
.unnanu-form-group-style-1 {
	margin-bottom: 12px;
}

.unnanu-form-group-style-2 {
	margin-bottom: 20px;
}

//Input Styles
select.form-control {
	font-size: 16px;
	margin: 0;
	border-radius: 0;
	height: 45px;
	padding: 0 16px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, black 50%),
		linear-gradient(135deg, black 50%, transparent 50%);
	background-position: calc(100% - 20px) calc(50%), calc(100% - 15px) calc(50%),
		calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;
	color: #222222;
}

input.unnanu-text-input-style-1 {
	height: 49px;
	border: solid 0.6px rgba(0, 0, 0, 0.2);
	background-color: #ffffff;
	color: #222222;
	letter-spacing: 0.4px;
	font-size: 16px;
	font-weight: 500;
	padding: 0 16px;
	box-shadow: none;
	border-radius: 0;
}

textarea.unnanu-text-input-style-1 {
	height: 96px;
	border: solid 0.6px rgba(0, 0, 0, 0.2);
	background-color: #ffffff;
	color: #222222;
	letter-spacing: 0.4px;
	font-size: 16px;
	font-weight: 500;
	padding: 2px 16px;
	box-shadow: none;
	border-radius: 0;
}

//Labels
.unnanu-input-label-style-1 {
	height: 18px;
	line-height: 18px;
	margin-bottom: 7px;
	font-size: 15px;
	font-weight: 500;
	letter-spacing: 0.3px;
	color: #222222;

	.requiredAsterix {
		color: #4a90e2;
		font-size: 15px;
		width: 2px;
		height: 2px;
		float: right;
		margin-left: 3px;
	}
}

.unnanu-input-label-style-2 {
	height: 18px;
	line-height: 18px;
	font-size: 15px;
	font-weight: 500;
	letter-spacing: 0.3px;
	color: #777777;
}

//Check Boxes
.unnanu-check-box-style-1 {
	width: 18px;
	height: 18px;
	position: relative;
	display: inline-block;

	input.form-check {
		width: 18px;
		height: 18px;
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		opacity: 0;
	}

	label {
		width: 18px;
		height: 18px;
		margin: 0;
		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 2px;
		box-shadow: none;
		border: 2px solid #010101;
		background-color: #ffffff;
		opacity: 0.54;

		&:after {
			content: "";
			width: 14px;
			height: 6px;
			position: absolute;
			top: 3px;
			left: 0px;
			border: 2px solid #fcfff4;
			border-top: none;
			border-right: none;
			background-color: rgba(0, 0, 0, 0);
			opacity: 0;
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}
	}

	input[type="checkbox"]:checked+label {
		background-color: #369eff;
		border: 2px solid #369eff;
		opacity: 1;

		&:after {
			opacity: 1;
		}
	}
}

//Icons
i.conversation-add-file-attachment {
	width: 23px;
	height: 24px;
}

//Messages
button.message-send-button {
	box-shadow: none;
	height: 40px;
	border-radius: 1px;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 0.4px;

	&:disabled {
		background-color: rgba(0, 0, 0, 0.08);
	}
}

//other elements

//custom css icons

.txt_StatAvail_PFCT {
	border: 2px solid #07cdff;
	color: #07cdff;
	padding: 4px 5px;
	line-height: 12px;
	border-radius: 4px;
	font-weight: 500;
	font-size: 12px;
	text-align: center;
	cursor: pointer;
}

.media-body,
.media-left,
.media-right {
	display: table-cell;
	vertical-align: top;
}
