@import "fonts-new.scss";
@import "reset-new.scss";
@import "ui-elements.scss";
@import "ui-elements-new.scss";

/* ------------------------------------------------------------
	SHARED STYLES
   ------------------------------------------------------------ */


//Header Nav Bar
header.app-header {
	position: absolute;
	width: 100%;
	top: 0;
	z-index: 99;
}

.cursor-pointer {
	cursor: pointer;
}

@keyframes placeHolderAnimation {
	0% {
		background-position: -468px 0
	}

	100% {
		background-position: 468px 0
	}
}

.animated-background {
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderAnimation;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	background-size: 800px 104px !important;
	height: 17px;
	position: relative;
	border-radius: 10px;
}

@import "buttons.scss";
@import 'modal-style.scss';
@import "unnanu-pro.scss";

.sign-in-up-alert {
	border-radius: 5px 0 0 5px;
	overflow: hidden;
	position: fixed;
	z-index: 999;
	width: 295px;
	height: 214px;
	right: 0;
	bottom: 120px;
	background: #2d69ce;
	box-shadow: rgba(0, 0, 0, 0.20) 0 4px 10px 0;
	opacity: 0;
	transform: translateX(50px);
	transition: all 0.8s;

	&.show {
		opacity: 1;
		transform: translateX(0);
	}

	&.hide {
		opacity: 0;
		transform: translateX(50px);
	}

	header {
		position: relative;
		height: 93px;
		overflow: hidden;

		img {
			width: 100%;
		}

		button {
			cursor: pointer;
			width: 22px;
			height: 22px;
			border: none;
			padding: 0;
			box-shadow: none;
			background: none;
			right: 10px;
			top: 10px;
			position: absolute;
		}
	}

	.alert-body {
		margin-top: 14px;
		font-size: 14px;
		line-height: 1.43;
		letter-spacing: 0.4px;
		text-align: center;
		color: #ffffff;

		.alert-apps {
			margin-top: 11px;

			a {
				margin: 0 5px;
			}
		}
	}
}

ngb-typeahead-window.dropdown-menu {
	width: 100%;
}

//Empty Cases
.no-conversations-left-wrapper {
	color: #989898;
	margin-top: 150px;

	img {
		width: 69px;
		margin-bottom: 5px;
	}

	h4 {
		font-size: 18px;
	}

	p {
		font-size: 15px;
	}
}

// Custom toast style
.toast-container {
	&.toast-top-center {
		top: 71px;
	}
	.custom-toastr {
		width: fit-content !important;
		text-align: center;
		z-index: 10002;
		&.ngx-toastr {
			padding: 8px 18px;
			border-radius: 6px;
			border-width: 1px;
			border-style: solid;
			font-size: 12px;
		}

		&.toast-success {
			color: #48ac68;
			background-color: #edfaf1;
			border-color: #48ac68;
			background-image: none;
		}

		&.toast-error {
			color: #e82110;
			background-color: #ffe3e3;
			border-color: #e82110;
			background-image: none;
		}

		&.toast-info {
			color: #2F96B4;
			background-color: #e3f4f9;
			border-color: #2F96B4;
			background-image: none;
		}

		&.toast-warning {
			color: #F89406;
			background-color: #f7e6cf;
			border-color: #F89406;
			background-image: none;
		}
	}
}

