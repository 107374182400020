$colorBlue_1 : #0a3898;
$colorBlue_1_hover : #0e54e6;

.smallButtonPreloader {
	display: inline-block;
	width: 30px;
	height: 30px;
	position: absolute;
	top: 9px;
	right: 9px;
	left: 103px;
}

.smallButtonPreloader:after {
	content: '';
	display: inline-block;
	width: 30px;
	height: 30px;
	background-image: url(../../images/icon-preload-white.png?1);
	-webkit-animation: rotateSpinner 1.2s linear infinite;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 1;
}

.extra-smallButtonPreloader {
	display: inline-block;
	width: 20px;
	height: 20px;
	position: absolute;
	top: 6px;
	right: 6px;
}

.extra-smallButtonPreloader:after {
	content: '';
	display: inline-block;
	width: 20px;
	height: 20px;
	background-image: url(../../images/icon-preload-white.png?1);
	background-size: 20px 20px;
	-webkit-animation: rotateSpinner 1.2s linear infinite;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 1;
}

.dashStatsPreloader {
	width: 100%;
	margin-top: 39px;
	margin-bottom: 21px;
	height: 67px;
	position: relative;
}

.dashStatsPreloader:after {
	content: '';
	display: inline-block;
	width: 30px;
	height: 30px;
	margin-left: -15px;
	margin-top: -15px;
	top: 50%;
	left: 50%;
	position: absolute;
	background-image: url(../../images/icon-preload.png?1);
	-webkit-animation: rotateSpinner 1.2s linear infinite;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 1;
}

.appCardPreload {
	background: none !important;
	border: none !important;
	box-shadow: none !important;
	height: 40px;
	position: relative;
}

.appCardPreload:after {
	content: '';
	display: inline-block;
	width: 30px;
	height: 30px;
	margin-left: -15px;
	margin-top: -15px;
	top: 50%;
	left: 50%;
	position: absolute;
	background-image: url(../../images/icon-preload.png?1);
	-webkit-animation: rotateSpinner 1.2s linear infinite;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 1;
}

//copy to clipboard notification
.copy {
	position: absolute;
}

// Uploading Badge
.badgeUploadingContainer {
	width: 294px;
	position: fixed;
	left: 7px;
	bottom: 7px;
	z-index: 100;

	ul.badgeUploading {
		list-style: none;
		padding: 0;
		margin: 0;

		.uploadingItem {

			width: 294px;

			.uploadItemContainer {
				padding: 18px 16px 16px;
				width: 294px;
				height: 95px;
				background-color: #ffffff;
				border-radius: 5px;
				box-shadow: 0px 3px 3px 1px #dedede;

				span.uploadingItemStatus {
					position: relative;
					width: 255px;
					height: 65px;
					display: inline-block;

					.badgeContentTitle {
						font-weight: 500;
						font-size: 13px;
						color: #5f5e5e;
					}

					.badgeContentSubTitle {
						font-size: 13px;
						color: #a5a3a3;
					}

					.badgePercentage {
						font-size: 16px;
						font-weight: 300;
						color: #1689ff;
						position: absolute;
						top: 0;
						right: 0;
					}

					.progress {
						border-radius: 0;
						height: 3px;
						background-color: #95c9ff;

						.progress-bar {
							background-color: #1689ff;
						}
					}

					.badgeStatusName {
						position: absolute;
						right: 0;
						bottom: 0;
					}
				}
			}
		}
	}
}

//Home Validation
.validWrap {
	position: absolute;
	top: 0;
	right: 23px;
	height: 100%;
	color: rgba(255, 0, 0, 0.6);
	font-size: 20px;
	font-weight: light;
}

.validWrap span {
	vertical-align: baseline;
	margin-top: 3px;
}

.errorAlert,
.app-landing .inputFormSet .form-group.errorAlert {
	margin: 0;
}

.errorMsg {
	width: 100%;
	color: rgba(255, 0, 0, 0.6);
	text-align: center;
	margin: 0;
}

//Preloader Rotate animation
@-webkit-keyframes rotateSpinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

// Popup animation 01
@-webkit-keyframes popIn {
	0% {
		opacity: 0;
		transform: scale(.7)
	}
}

//Camera / Video Toggle Animation
@-webkit-keyframes normalCameraAnimation {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 0;
	}

	30% {
		opacity: 1;
	}

	60% {
		opacity: 1;
	}

	80% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

@-webkit-keyframes videoCameraAnimation {
	0% {
		opacity: 1;
	}

	20% {
		opacity: 1;
	}

	30% {
		opacity: 0;
	}

	60% {
		opacity: 0;
	}

	80% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

//Navbar UI
.topNavigationBarWrapper .navbar-toggle .icon-bar {
	background-color: #ccc;
}

.topNavigationBarWrapper .navbar-toggle {
	margin-top: 18px;
	margin-bottom: 18px;
}

// Dashboard Navbar Icons
.topNavigationBarWrapper.navbar .navbar-nav [class^="dashIcons"] {
	font-size: 32px;
	position: relative;
}

.topNavigationBarWrapper.navbar .notifications {
	background-color: #FF4C40;
	position: absolute;
	right: -5px;
	top: -5px;
	font-size: 10px;
	text-shadow: none;
}

[class*="navbar-ct"] .notifications {
	background-color: #FFFFFF;
	color: #FF4C40;
}

//Customize Select Combobox
select.form-control {

	/* reset */
	margin: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;

	background-image:
		linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%);
	background-position:
		calc(100% - 20px) calc(50%),
		calc(100% - 15px) calc(50%),
		calc(100% - 2.5em) 0.5em;
	background-size:
		5px 5px,
		5px 5px,
		1px 1.5em;
	background-repeat: no-repeat;
}

select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #000;
}



// Add Profile Picture Drop Down Menu
.uiContextualLayerPositioner {
	height: 0;
	position: absolute;
}

.photoDropdownContainer.dropdown {
	width: 138px;
	margin: 0 auto;
}

.photoDropdownContainer.dropdown .photoDropdownButton {
	position: absolute;
	margin-top: -138px;
	left: 0;
	padding: 0;
	z-index: 300;
	background: none;
	width: 138px;
	height: 138px;
	border: none;
}

.photoDropdownContainer ul.dropdown-menu:before {
	position: absolute;
	top: -10px;
	right: 12px;
	display: inline-block;
	border-right: 10px solid transparent;
	border-bottom: 10px solid #fff;
	border-left: 10px solid transparent;
	border-bottom-color: #ffffff;
	content: '';
}

.videoUploadNoteContainer {
	margin-top: 105px;
	color: #fff;
}

.videoUploadNoteContainer h4 {
	font-size: 16px;
	font-weight: 600;
}

.videoUploadNoteContainer p {
	font-weight: 500;
	font-size: 13px;
}

.create-acc-buttoons-wrapper {}

.create-acc-buttoons-wrapper button {
	min-width: 156px;
	height: 40px;
}

#createAccSkipButton {
	color: #266adc;
	//background-color:#0f42af;
}

#createAccSkipButton:hover {
	color: #5b8ee4;
	//background-color:#2152bd;
}

//Photo Upload Button
.fileUploadBtn {
	display: block;
	text-align: left;
	padding: 3px 20px;
	font-weight: 400;
	font-size: 14px;
	border: 0;
}

.fileUploadBtn:hover {
	background-color: #f5f5f5;
}

//Bootstrap Panel custom styles

.panel-default>.panel-heading {
	background-color: #ffffff;
	border-bottom: none;
}

.ddrr {
	color: #999999;
	font-size: 14px;
}

//Empty Panel
.panel-empty {
	background: none;
	box-shadow: none !important;
	color: #989898;
}

.panel-empty .img_emptyResumePanel {
	margin-bottom: 15px;
}

.panel-empty h3 {
	font-size: 22px;
	font-weight: 400;
}

.panel-empty .panel-footer {
	font-size: 18px;
}

//Unnanu Checkbox
.checkUnnanuInput {
	padding-right: 15px;
	width: 16px;
	height: 16px;
	margin-top: 2px;
	margin-right: 15px;
	float: left;
	position: relative;
	display: block;

	input.form-check {
		width: 16px;
		height: 16px;
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
	}

	label {
		width: 16px;
		height: 16px;
		margin: 0;
		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;
		background-color: $colorBlue_1;
		border-radius: 2px;
		box-shadow: none;
		border: 2px solid #707070;
		background-color: #ffffff;
	}

	label:after {
		content: '';
		width: 9px;
		height: 5px;
		position: absolute;
		top: 3px;
		left: 2px;
		border: 2px solid #fcfff4;
		border-top: none;
		border-right: none;
		background: transparent;
		opacity: 0;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}

	label:hover::after {
		opacity: 0.3;
	}

	input[type=checkbox] {
		visibility: hidden;
	}

	input[type=checkbox]:checked+label {
		background-color: $colorBlue_1;
		border: 2px solid $colorBlue_1;
	}

	input[type=checkbox]:checked+label:after {
		opacity: 1;
	}
}

//Breadcrumb
.unnanu-breadcrumb {
	ol.breadcrumb {
		li {
			color: #565656;
			font-size: 16px;

			a {
				color: #1862d1;
			}
		}
	}
}




//Alert Popup style
.ngdialog.ngdialog-theme-unnanu {
	&.alert-pop-up-style-v2 {
		.ngdialog-content {
			padding: 0;

			.ngAppPopupDialog {
				.ngdialog-header {
					border: 0;
					margin: 0;
					padding: 28px 24px 14px;

					h4 {
						font-size: 22px;
						margin: 0;
					}
				}

				.ngdialog-message {
					padding: 0 24px;
					font-size: 16px;
					letter-spacing: 0.1px;
					line-height: 22px;

					p {
						color: #777777;
					}
				}

				.ngdialog-buttons {
					padding: 15px 19px;
					border: 0;

					.button-wrapper {
						button {
							background: none;

							&:hover {
								background: none;
							}
						}

						button.btn {
							font-size: 19px;
							font-weight: 500;
							color: #52a7db;
							text-transform: uppercase;
							border: 0;
						}

						button.btn-no {
							color: #989898;
						}
					}
				}
			}
		}
	}
}
