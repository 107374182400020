//Lato Medium latin-ext
@font-face {
	font-family: 'Lato';
	font-weight: normal;
	font-style: normal;
	font-weight: 500;
	src: url('/assets/fonts/lato/lato-medium-webfont.woff2') format('woff2'),
		url('/assets/fonts/lato/lato-medium-webfont.woff') format('woff');
	//unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

//Frank Ruhl Libre
@import url('https://fonts.googleapis.com/css?family=Frank+Ruhl+Libre');
