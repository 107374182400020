body {
	font-family: 'Lato', sans-serif;
	background-color: #ededed;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

body,
html {
	&.ngdialog-open {
		height: auto !important;
	}
}
